<template>
  <div>
    <div class="title">
      <!-- <showtime type="4"></showtime> -->
      <writetime type="4"></writetime>
      <div class="title_1" @click="goback">
        <img src="../../assets/zjt.png" alt="" />
        返回
      </div>
      <div class="title_2">
        <span @click="goback" style="cursor: pointer">{{
          $route.query.up
        }}</span>
        >
        {{ $route.query.tabtitle }}
        <span v-if="$route.query.title"> > {{ $route.query.title }}</span>
      </div>
    </div>
    <div class="pattern">
      <div>
        <span v-if="rows.kindof == 1">判断题</span>
        <span v-if="rows.kindof == 2">单选题</span>
        <span v-if="rows.kindof == 3">多选题</span>
        <span v-if="rows.kindof == 4">问答题</span>
      </div>

      <div class="right">
        <span>{{ index }}</span
        >/{{ rows ? rows.all : 0 }}
      </div>
    </div>
    <div class="topic">
      <div class="title1">
        {{ index }}.
        <span v-html="rows.title"></span>
      </div>
      <ul>
        <li
          :class="item.active ? 'active' : ''"
          v-for="(item, inx) in rows.ans"
          :key="inx"
          @click="selectRow(item)"
        >
          {{ item.flata }}. <span v-html="item.title"></span>
        </li>
      </ul>
      <div class="msg" @click="look" v-if="$route.query.type != 1 && rows">
        <i class="icon iconfont icon-dengpao"></i><span>答案提示</span>
      </div>
      <!-- <div class="answer">您选择的答案是：<span></span></div> -->
      <el-button
        @keyup.enter.native="submit;"
        class="btn"
        type="primary"
        :disabled="select.length == 0"
        @click="submit"
        >{{ index == rows.all ? "完成" : "下一题" }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import base from "../../libs/base";

export default {
  data() {
    return {
      title: "",
      textid: "", //下一题id
      rows: {},
      select: [], //选择的答案
      index: 1, //当前题数
      type: "",
      leveTime: null, //离开页面时间
      backTime: null, //返回页面时间
      begin_time: null,
      reset: true,
      popup_time: null,
      human_time: null,
      Socket_sta: null,
      Socket_end: null,
      screen: true, //切屏停止计时
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      classid: (state) => state.classid,
      details: (state) => state.details,
    }),
  },
  methods: {
    ...mapActions([
      "getMakeQuestion",
      "getCalculateSectionTest",
      "getErrorSubject",
      "getErrorBankResults",
      "getSectionTestScores",
      "getscreenCuttingDuration",
    ]),

    //查看提示
    look() {
      this.$alert(`正确答案${this.rows.iscorrect}`, "答案提示", {
        confirmButtonText: "确定",
        callback: (action) => {},
      });
    },
    //1.模拟考试 5.章节练习 6.总题库
    getTest() {
      this.getMakeQuestion({
        username: this.userInfo.username,
        class: this.classid,
        type: this.$route.query.type,
        rule: this.$route.query.rule ? this.$route.query.rule : "",
        right: this.index ? this.index : "",
        textid: this.textid,
      }).then((res) => {
        if (res.code == 200) {
          if (res.data[0].right) {
            this.index = res.data[0].right;
          }
          //this.index = this.index+1;
          this.rows = res.data[0];
          this.textid = res.data[0].textid;
        } else {
          this.$message({
            message: res.msg,
            type: res.code == 200 ? "success" : "cancel",
          });
        }
      });
    },
    //选择答案
    selectRow(item) {
      if (this.rows.kindof == 1 || this.rows.kindof == 2) {
        //单选
        this.rows.ans = this.rows.ans.map((res) => {
          res.active = res.title == item.title ? true : false;
          return res;
        });
        this.select = [item.flata];
      } else if (this.rows.kindof == 3) {
        //多选
        item.active = !item.active;
        this.select = [];
        this.rows.ans.map((e) => {
          if (e.active) {
            this.select.push(e.flata);
          }
        });

        let list = this.rows;
        this.rows = [];
        this.rows = list;
      }
    },
    goback() {
      this.$router.go(-1);
    },
    //提交答案
    submit() {
      if (this.select.length == 0) {
        this.$message({
          message: "请选择答案",
          type: "cancel",
        });
        return false;
      }

      if (this.$route.query.type == 2) {
        this.getErrorResuls();
      } else {
        this.getTestCall();
      }
    },
    getErrorResuls() {
      this.getErrorBankResults({
        username: this.userInfo.username,
        id: this.rows.id,
        kindof: this.$route.query.type,
        answer: this.select.join(""),
        iscorrect: this.rows.iscorrect,
      }).then((res) => {
        this.$message({
          message:
            this.rows.iscorrect.replace(/\s+/g, "") ==
            this.select.join("").replace(/\s+/g, "")
              ? "正确"
              : "错误",
          type:
            this.rows.iscorrect.replace(/\s+/g, "") ==
            this.select.join("").replace(/\s+/g, "")
              ? "success"
              : "cancel",
        });

        if (res.code == 200) {
          if (this.index == this.rows.all) {
            //答题完成
            setTimeout(() => {
              this.$message({
                message: "所有错题已经回答完毕",
                type: "success",
              });
            }, 500);

            return false;
          }
          this.index++;
          this.rows = "";
          this.getErrorData();
        }
      });
    },
    //回调
    getTestCall() {
      this.getCalculateSectionTest({
        username: this.userInfo.username,
        class: this.classid,
        subjectId: this.rows.id,
        kindof: this.$route.query.type,
        answer: this.select.join(""),
      }).then((res) => {
        this.$message({
          message:
            this.rows.iscorrect.replace(/\s+/g, "") ==
            this.select.join("").replace(/\s+/g, "")
              ? "正确"
              : "错误",
          type:
            this.rows.iscorrect.replace(/\s+/g, "") ==
            this.select.join("").replace(/\s+/g, "")
              ? "success"
              : "cancel",
        });

        if (res.code == 200) {
          this.select = [];
          if (this.index == this.rows.all) {
            if (this.type == 5) {
              //总题库
              this.$router.push({
                path: "/Layoutstudy/practiceresults",
                query: {
                  up: this.$route.query.up,
                  tabtitle: this.$route.query.tabtitle,
                  textid: this.textid,
                  id: this.$route.query.id,
                  type: this.type,
                  rule: this.$route.query.rule ? this.$route.query.rule : "",
                },
              });
            } else if (this.type == 1) {
              //答题完成
              this.$router.push({
                path: "/Layoutstudy/practiceresults",
                query: {
                  up: this.$route.query.up,
                  tabtitle: this.$route.query.tabtitle,
                  textid: this.textid,
                  id: this.$route.query.id,
                  type: this.type,
                  rule: this.$route.query.rule ? this.$route.query.rule : "",
                },
              });
            } else {
              this.$message({
                message: "所有错题已经回答完毕",
                type: "success",
              });
              this.$router.back();
              return false;
            }
            return false;
          } else {
            this.index++;
            this.rows = "";
            this.getTest();
          }
        }
      });
    },
    //错题练习
    getErrorData() {
      this.getErrorSubject({
        username: this.userInfo.username,
        class: this.classid,
        type: this.$route.query.type,
        page: this.index == 1 ? "1" : 1, //下一题 默认传1
      }).then((res) => {
        if (res.code == 200) {
          let ans = res.data.answer.split(",");
          ans = ans.map((e) => {
            let row = {
              title:
                e.indexOf("：") > -1
                  ? e.split("：")[1].replace(/\s+/g, "")
                  : e.split(":")[1].replace(/\s+/g, ""),
              flata:
                e.indexOf("：") > -1
                  ? e.split("：")[0].replace(/\s+/g, "")
                  : e.split(":")[0].replace(/\s+/g, ""),
            };
            return row;
          });
          let data = {
            id: res.data.id,
            ans: ans,
            iscorrect: res.data.iscorrect,
            kindof: res.data.kindof,
            title: res.data.title,
            all: res.data.allnum,
          };
          this.select = [];
          this.rows = data;
        } else {
          this.$router.go(-1);
          this.$message({
            message: res.msg,
            type: res.code == 200 ? "success" : "cancel",
          });
        }
      });
    },
    socketInit() {
      this.socket = new WebSocket(base.socketUrl);
      var that = this;

      this.Socket_sta = new Date();
      this.socket.onopen = function () {
        //补回连接中的时间段
        that.Socket_end = new Date();
        var date3 = that.Socket_end.getTime() - that.Socket_sta.getTime();

        that.getscreenCuttingDuration({
          username: that.userInfo.username,
          user_id: that.userInfo.id,
          class_id: that.details.id,
          video_time: (date3 / 1000).toFixed(0),
          source: 4,
        });
        // }

        that.socketLoad = true;
      };
    },
    sendSocketMsg() {
      this.time = setInterval(() => {
        this.lock = true;
        if (this.socketLoad) {
          this.socket.send(
            JSON.stringify({
              type: 1,
              classId: this.details.id,
              userId: this.userInfo.id,
              source: 4,
            })
          );
        }
      }, 5 * 60 * 1000);
    },
    getdocument() {
      let that = this;
      if (document.hidden) {
        this.screen = false;
        // 页面被挂起
        that.leveTime = new Date();
      } else {
        this.screen = true;
        // 页面呼出
        that.backTime = new Date();
        var date3 = that.backTime.getTime() - that.leveTime.getTime();
        //停止计时
        if (that.socketLoad) {
          // if ((date3 / 1000).toFixed(0) > 2700) {
          //   // 超过45分钟，只上传45分钟
          //   that.getscreenCuttingDuration({
          //     username: that.userInfo.username,
          //     user_id: that.userInfo.id,
          //     class_id: that.details.id,
          //     video_time: 2700,
          //   });
          // } else {
          that.getscreenCuttingDuration({
            username: that.userInfo.username,
            user_id: that.userInfo.id,
            class_id: that.details.id,
            video_time: (date3 / 1000).toFixed(0),
            source: 4,
          });
          // }
        }
      }
    },
  },
  mounted() {
    let g = this;
    this.type = this.$route.query.type;
    let that = this;
    if (this.$route.query.type == 1) {
      this.title = "综合测试";
      this.getTest();
      // this.socketInit();
      // this.sendSocketMsg();
      // document.addEventListener("visibilitychange", that.getdocument, false);
    } else if (this.$route.query.type == 5) {
      this.title = "章节练习";
      this.getTest();
    } else if (this.$route.query.type == 6) {
      this.title = "题库练习";
      this.getTest();
    } else if (this.$route.query.type == 2) {
      this.title = "错题练习";
      this.getErrorData();
    }
    document.onkeydown = function (e) {
      let key = window.event.keyCode;
      if (key == 13) {
        g.submit();
      }
    };
  },
  beforeDestroy() {
    let that = this;
    //必须内存地址相同才能移出方法

    this.flag1 = false;
    clearInterval(this.time);
    clearTimeout(this.human_time);
    clearTimeout(this.dynamic_time);
    clearTimeout(this.begin_time);
    // this.socket.close();
    // document.removeEventListener("visibilitychange", that.getdocument, false);
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  height: 45px;
  .title_1 {
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #676767;
    img {
      margin-right: 5px;
      height: 20px;
      vertical-align: middle;
    }
  }
  .title_2 {
    margin-left: 24px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
}
.pattern {
  margin-left: 11px;
  margin-top: 31px;
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #137cfb;
  display: flex;
  justify-content: space-between;
  .right {
    color: #000;
    span {
      color: #fc5430;
    }
  }
}
::v-deep .topic {
  margin-left: 11px;

  margin-top: 17px;
  .title1 {
    margin-bottom: 43px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    img {
      width: 350px;
      height: auto;
    }
  }
  ul {
    .active {
      border: 2px solid #137cfb;
      padding: 11px 19px;
    }
    li {
      &:hover {
        border: 2px solid #137cfb;
        padding: 11px 19px;
      }
      img {
        width: 350px;
        height: auto;
      }
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      padding: 13px 21px;
      box-sizing: border-box;
      margin-bottom: 16px;

      background: #fafafa;
      width: 527px;
      // height: 50px;
    }
  }
  .msg {
    padding: 16px 0;
    text-align: left;
    font-size: 18px;
    color: #222222;

    .icon {
      color: #0039ff;
      font-size: 22px;
      margin-right: 5px;
      cursor: pointer;
    }
    span {
      cursor: pointer;
    }
  }
  .answer {
    margin-top: 30px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    span {
      color: #137cfb;
    }
  }
  .btn {
    margin-top: 65px;
    margin-bottom: 500px;
    color: #fff;
    text-align: center;
    width: 235px;
    height: 39px;
    background: #137cfb;
  }
  .arduy {
    pointer-events: none;
    background: #ccc;
  }
}
</style>
